<!-- 대관신청 예약화면에서 푸터 숨김!! -->
<template>
  <section class="reservation_app">
    <!-- Calendar Section -->
    <div class="calendar-section mobile">
      <Datepicker
        :inline="true"
        :monday-first="true"
        :disabledDates="disabledDates"
        :minimumView="'day'"
        :maximumView="'day'"
        v-model="viewDate"
        @selected="setSelectedDate"
        @changed-month="onChange"
        ref="calendar"
      ></Datepicker>
      <!-- <p class="description">* {{$t('content.event.MobileEventReservation.txt09')}}</p> -->
    </div>
    <!-- // Calendar Section -->
    <div class="form_group tline">
      <div class="input_btn_group col3">
        <span class="btn_input radio" v-for="(room, idx) in roomInfo" :key="idx" v-show="room.show">
          <input type="checkbox"
             name="roomInfo"
            :id="room.target"
            :disabled="room.disabled"
            @click="onClickRoomSelect(room.target)"
            v-model="room.check" style="display: none;">
          <label :for="room.target">{{ room.name }}</label>
        
        </span>
      </div>
    </div>
    <div class="form_group tline">
      <input type="text"
        readonly="readonly"
        v-model="filterTime"
        @click.prevent="openPopup('timeParams')"
        class="btn_input timepicker"
        :placeholder="`${startPlTime} ~ ${endPlTime}`"
        :disabled="isDisabledTime"
      >

      <FilterTimePopup
        :params="timeParams"
        :time-step="30"
        :start-hour="9"
        :startMinute="isSat ? 30 : 0"
        :selected-date="this.$moment(viewDate).format('YYYY-MM-DD')"
        :disabled-time-index="disabledTimeIndex"
        :minTimeTick="4"
        :maxTimeTick="24"
        :endHour="isSat ? 9 : 0"
        :clearSaveTimes="clearSaveTimes"
        @selected="onSelectedTimeFilter"
      />
    </div>
    <FixedButton :isFooter="isFooter">
      <template slot="buttons">
        <button class="btn-ghost"
        @click="goHistory"
        >{{$t('content.event.EventReservation.txt72')}}</button>
        <button class="btn-basic"
          @click="onReservate"
          :disabled="isDisabledNext"
        >{{$t('content.event.EventReservation.txt73')}}</button>
      </template>
    </FixedButton>
  </section>
</template>

<script>
import { APIs } from '@/common/portalApi';
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import Datepicker from '@sum.cumo/vue-datepicker';
import '@sum.cumo/vue-datepicker/dist/vuejs-datepicker.css';
import FilterTimePopup from '@/components/popup/FilterTimePopup.vue';
import FixedButton from '@/components/base/FixedButton.vue';
// import { getChangeDate, getHolidaysMonth } from '@/common/holidays';

export default {
  name: 'MobileReservation',
  mixins: [mixinHelperUtils],
  components: {
    FilterTimePopup,
    Datepicker,
    FixedButton,
  },
  metaInfo() {
    return {
      meta: this.$route.meta.metaInfo
    };
  },
  data() {
    return {
      roomChecked: [],
      reservationInfoMap: null,
      filterTimeTick: 0,
      clearSaveTimes: false,
      disabledTimeIndex: [],
      filterTime: null,
      startPlTime: this.$t('content.event.MobileEventReservation.txt12'),
      endPlTime: this.$t('content.event.MobileEventReservation.txt13'),
      // isVisitTime: '',
      userInfo: undefined,
      codeDivisionCenter: 'CT101',
      viewDate: undefined, // 실제 사용되는 시간
      activeDate: undefined, // 초기 설정을 위한 임시날짜
      queryDate: undefined, // 쿼리 스트링으로 받아오는 날짜
      disabledDates: undefined,
      holidays: [],
      weekDay: 0,
      timeParams: {
        open: false,
      },
      roomInfo: [
        {
          target: 'event',
          name: this.$t('commonCode.CT201'),
          mapping: ['Main Hall'],
          reservationMappingId: ['6'],
          check: false,
          disabled: false,
          show: true,
          fullReservation: false,
        }, {
          target: 'multiA',
          name: this.$t('commonCode.CT202'),
          mapping: ['Multi Room A'],
          reservationMappingId: ['7'],
          check: false,
          disabled: false,
          show: true,
          fullReservation: false,
        }, {
          target: 'multiB',
          name: this.$t('commonCode.CT203'),
          mapping: ['Multi Room B'],
          reservationMappingId: ['8'],
          check: false,
          disabled: false,
          show: true,
          fullReservation: false,
        }, {
          target: 'multiC',
          name: this.$t('commonCode.CT204'),
          mapping: ['Multi Room C'],
          reservationMappingId: ['9'],
          check: false,
          disabled: false,
          show: true,
          fullReservation: false,
        }, {
          target: 'multiBC',
          name: this.$t('commonCode.CT203_4'),
          mapping: ['Multi Room B', 'Multi Room C'],
          reservationMappingId: ['8', '9'],
          check: false,
          disabled: false,
          show: true,
          fullReservation: false,
        }, {
          target: 'multiD',
          name: this.$t('commonCode.CT205'),
          mapping: ['Multi Room D'],
          reservationMappingId: ['10'],
          check: false,
          disabled: false,
          show: true,
          fullReservation: false,
        }, {
          target: 'multiE',
          name: this.$t('commonCode.CT206'),
          mapping: ['Multi Room E'],
          reservationMappingId: ['11'],
          check: false,
          disabled: false,
          show: true,
          fullReservation: false,
        }, {
          target: 'multiDE',
          name: this.$t('commonCode.CT205_6'),
          mapping: ['Multi Room D', 'Multi Room E'],
          reservationMappingId: ['10', '11'],
          check: false,
          disabled: false,
          showSat: true,
          show: true,
          fullReservation: false,
        },
      ],
    };
  },
  computed: {
    isSat() { // 토/일 체크
      return this.$moment(this.viewDate).day() === 6 || this.$moment(this.viewDate).day() === 0;
    },
    isDisabledTime() { // 시작 종료 시간 활성화
      return !this.roomInfo.filter(({ check }) => check).length;
    },
    isDisabledNext() { // 대관신청 버튼 활성화
      return !(this.filterTime && this.roomInfo.filter(({ check }) => check).length);
    },
  },
  created() {
    this.userInfo = this.$store.getters.getUserInfo();
    this.queryDate = this.$route.query.Date ? this.$route.query.Date : '';
    const limitDate = this.$moment().add(7, 'day');
    this.activeDate = this.$moment().add(8, 'day');
    const limitFrom = this.$moment().add(2, 'months');
    this.disabledDates = {
      to: limitDate.toDate(),
      from: limitFrom.toDate(),
      days: [],
      dates: this.holidays,
    };
    if (this.userInfo.member_role === 'MB202' || !!this.userInfo.external_company_no) {
      // this.disabledDates.days = [0, 6];
    } else if (this.activeDate.day() === 6) {
      // this.activeDate = this.$moment().add(10, 'day');
    } else if (this.activeDate.day() === 0) {
      // this.activeDate = this.$moment().add(9, 'day');
    }
  },
  mounted() {
    const date = this.queryDate ? this.$moment(this.queryDate).toDate() : this.activeDate.toDate();
    this.viewDate = this.addHolidayDate(date);
    this.setSelectedDate(this.viewDate);
    // this.showAlert(this.$t('content.event.EventReservation.alertMsg.msg1'));
    // footer 유무 설정
    this.setFooter(false);
  },
  methods: {
    addHolidayDate(date) { // 월 변화시 휴일 및 주말 체크
      this.holidays = [];
      // const monthHolidays = getHolidaysMonth(this.$moment(date).format('YYYY'),
      //   this.$moment(date).format('MM'));
      // if (monthHolidays !== undefined) {
      //   monthHolidays.forEach((day) => {
      //     this.holidays.push(new Date(getChangeDate(day)));
      //   });
      // }
      // this.disabledDates.dates = this.holidays;

      // const week = this.$moment(date).day();
      // if (week === 0) {
      //   this.weekDay = 1;
      // } else if (week === 6) {
      //   this.weekDay = 2;
      // } else {
      //   this.weekDay = 0;
      // }
      // date = this.$moment(date).add(this.weekDay, 'day').toDate();
      // this.holidays.forEach((day) => {
      //   if (this.$moment(day).format('YYYY-MM-DD') === this.$moment(date).format('YYYY-MM-DD')) {
      //     date = this.$moment(date).add(1, 'day').toDate();
      //   }
      // });
      return date;
    },
    onChange(date) { // 월 이동 이벤트
      date = (date < this.activeDate.toDate()) ? this.activeDate.toDate() : date;
      this.setSelectedDate(this.addHolidayDate(date));
    },
    setSelectedDate(date) {
      this.viewDate = date;
      this.retrieve();
      this.initRooms();
      this.enableRooms(date);
      // this.disableRooms(date);
      this.clearSaveTimes = true;
    },
    onSelectedTimeFilter(times) { //
      if (times.length === 0) {
        this.filterTime = '';
      } else if (times.length > 0) {
        const first = times[0].split(' ~ ')[0];
        const last = times[times.length - 1].split(' ~ ')[1];
        this.filterTime = `${first} ~ ${last}`;
      }
      this.filterTimeTick = times.length;
    },
    toHour(time) { // 예약시간 index start ~ end
      return Number(time.substr(0, 2)) * 2 - 18 + (Number(time.charAt(2)) > 0 ? 1 : 0);
    },
    initRooms() { // 룸 선택 초기화
      const roomList = this.roomInfo;
      roomList.forEach((room) => {
        room.check = false;
      });
    },
    enableRooms() { // 룸 비활성화
      // if (this.$moment(date).day() === 6) {
      //   this.roomInfo.forEach((room) => {
      //     room.disabled = true;
      //   });
      // } else {
      this.roomInfo.forEach((room) => {
        room.disabled = false;
      });
      // }
    },
    toggleRooms(arr, isChecked) { // 멀티룸 B+C, 멀티룸 D+E 토글
      const roomList = this.roomInfo;
      roomList.forEach((room) => {
        if (arr.includes(room.target)) {
          room.check = false;
          room.disabled = isChecked;
        }
      });
    },
    onClickRoomSelect(name) { // 룸선택
      this.clearSaveTimes = true;
      const roomList = this.roomInfo;
      const roomArr = roomList.filter(({ target }) => target === name);
      const room = roomArr[0];
      room.check = !room.check;
      const roomCheck = room.check;

        if (name === 'multiBC') {
          this.toggleRooms(['multiB', 'multiC'], roomCheck);
        } else if (name === 'multiDE') {
          this.toggleRooms(['multiD', 'multiE'], roomCheck);
        }

        const targets = roomList.filter(({ check }) => check).map(({ target }) => target);
        const checkBC = ['multiB', 'multiC'].every((b) => targets.includes(b));
        const targetBCarr = this.roomInfo.filter(({ target }) => target === 'multiBC');
        const targetBC = targetBCarr[0];
        if (!targetBC.fullReservation && checkBC && !targetBC.disabled) {
          targetBC.check = true;
          this.toggleRooms(['multiB', 'multiC'], roomCheck);
        }

        const checkDE = ['multiD', 'multiE'].every((b) => targets.includes(b));
        const targetDEarr = this.roomInfo.filter(({ target }) => target === 'multiDE');
        const targetDE = targetDEarr[0];
        if (!targetDE.fullReservation && checkDE && !targetDE.disabled) {
          targetDE.check = true;
          this.toggleRooms(['multiD', 'multiE'], roomCheck);
        }

      if (this.filterTime) {
        this.filterTime = '';
        this.filterTimeTick = 0;
      }
      this.sumReservationTime(this.roomInfo.filter(({ check }) => check));
    },
    sumReservationTime(checkedRooms) { // 룸 예약시간 합
      const array = checkedRooms.reduce((arr, info) => arr.concat(info.reservationMappingId), []);
      const reservationInfoMapC = this.reservationInfoMap;
      const disabledTimeIndex = array.reduce(
        (arr, roomId) => [...arr, ...(reservationInfoMapC?.get(roomId) || [])], [],
      );
      this.disabledTimeIndex = Array.from(new Set(disabledTimeIndex));
    },
    openPopup(name) {
      const checkedRooms = this.roomInfo.filter(({ check }) => check);
      if (checkedRooms.length) {
        this[name].open = true;
      }
      if (!this.filterTime) {
        this.showToast(this.$t('alertMessage.invalidVenueHour'), 3000); // 팝업 시간 기본 1500
      }
    },
    onReservate() {
      const params = { rooms: [] };
      params.rooms = this.roomInfo.filter(({ check }) => check);
      params.rooms = params.rooms.reduce((arr, info) => arr.concat(info.reservationMappingId), []);
      if (params.rooms.includes('10') && (!params.rooms.includes('11') && !params.rooms.includes('6'))) {
        // D룸 단독 신청
        this.showAlert(this.$t('alertMessage.invalidVenueRole2'));
      } else if (params.rooms.includes('11')
        && (!params.rooms.includes('10') && !params.rooms.includes('6'))) {
        // E룸 단독 신청
        this.showAlert(this.$t('alertMessage.invalidVenueRole1'));
      } else if ((params.rooms.includes('7') || params.rooms.includes('8') || params.rooms.includes('9'))
        && !params.rooms.includes('6')) {
        // 멀티룸 단독 신청
        this.showAlert(this.$t('alertMessage.invalidVenueRoom'));
      } else {
        const [start, end] = this.filterTime.split(' ~ ');
        
        params.startTime = `${this.$moment(this.viewDate).format('YYYY-MM-DD')} ${start}:00`;
        params.startTime = this.$moment(params.startTime).format('YYYY-MM-DDTHH:mm:ssZ');
        params.startTime = this.$moment(params.startTime);

        params.endTime = `${this.$moment(this.viewDate).format('YYYY-MM-DD')} ${end}:00`;
        params.endTime = this.$moment(params.endTime).format('YYYY-MM-DDTHH:mm:ss.ssZ');
        params.endTime = this.$moment(params.endTime);

        if (this.$moment(this.viewDate).format('YYYYMMDD') !== this.$route.query.Date) {
          this.$router.replace(`?Date=${this.$moment(this.viewDate).format('YYYYMMDD')}`);
        }

        // 중복 룸 제거
        const set = new Set(params.rooms);
        params.rooms = [...set];
        // sort
        params.rooms = params.rooms.sort((a, b) =>a-b);
        this.$router.push({ name: 'ReservationApply', params });
      }
    },
    goHistory() {
      if (this.$moment(this.viewDate).format('YYYYMMDD') !== this.$route.query.Date) {
        this.$router.replace(`?Date=${this.$moment(this.viewDate).format('YYYYMMDD')}`);
      }
      this.$router.push({ name: 'ReservationHistory' });
    },
    retrieve() {
      const borrowDate = this.$moment(this.$data.viewDate).format('YYYY.MM.DD');
      // 대관 휴일
      this.portalApiClient({
        url: APIs.CENTER_GANGNAM_VENUE_HOLIDAY,
        data: {
          date1: borrowDate
        },
      }).then(({ data }) => {
        if (data.result) {
          this.stopList = data.list;
          this.stopList.forEach((v) => {})
          // 대관중지일
          if (this.stopList.length) {
            this.stopList.forEach((v) => {
              let disabledRoom;
              if (v.placeId == 37) {
                // D + E
                disabledRoom = this.roomInfo.filter((room) => 
                room.reservationMappingId.includes('10') || room.reservationMappingId.includes('11'));
                disabledRoom.forEach((room) => {
                  room.disabled = true;
                });
              } else if (v.placeId == 36) {
                // D + E
                disabledRoom = this.roomInfo.filter((room) => 
                room.reservationMappingId.includes('8') || room.reservationMappingId.includes('9'));
                disabledRoom.forEach((room) => {
                  room.disabled = true;
                });
              } else {
                disabledRoom = this.roomInfo.filter((room) => room.reservationMappingId.includes(`${v.placeId}`));
              }
              disabledRoom.forEach((room) => {
                room.disabled = true;
              });
            });
          }
          this.getList()
        }
      });
    },
    getList() { // 예약일 조회
      const borrowDate = this.$moment(this.$data.viewDate).format('YYYY.MM.DD');
      this.portalApiClient({
        url: APIs.CENTER_GANGNAM_VENUE_LIST,
        data: {
          date1: borrowDate,
          // code_division_center: this.codeDivisionCenter,
        },
      }).then(({ data }) => {
        if (data.result) {
          this.reservationInfoMap = new Map();
          const startTime = this.$moment(this.$data.viewDate).set('hour', 9).set('minutes', this.isSat? 30:0);
          if (data.list && data.list.length > 0) {
            const reservationInfoMapArr = new Map();
            data.list.forEach((info) => {
              const ids = info.reservedPlaceIds.split(',');
              for (let i = 0; i < ids.length; i++) {
                const indexArr = reservationInfoMapArr.get(ids[i]) || [];
                let start = this.$moment(info.startTime.replace(/\./g, '-'))
                const end = this.$moment(info.endTime.replace(/\./g, '-'))
                // eslint-disable-next-line no-plusplus
                while (end.diff(start) > 0) {
                  const diff = start.diff(startTime);
                  const index = (diff / (1000 * 60 * 30)).toFixed(0) * 1
                  if (!indexArr.includes(index)) {
                    indexArr.push(index);
                  }
                  // 30분 간격
                  start.add('minutes', 30);
                }
                reservationInfoMapArr.set(ids[i], indexArr);
              }
            });
            if (reservationInfoMapArr.size) {
              // let mainTimes = reservationInfoMapArr.get('6')
              for (let [key, mainTimes] of reservationInfoMapArr.entries()) { 
                let addTime = []
                if (mainTimes) {
                  let tmp = -1, start = -1, continuous = 0;
                  for(const time of mainTimes) {
                    if (time == tmp+1) {
                      if (start == -1) start = tmp
                      ++continuous
                    } else if (continuous>0) {
                      addTime.push(start-1)
                      addTime.push(start-2)
                      addTime.push(tmp+1)
                      addTime.push(tmp+2)
                      continuous = 0
                      start = -1
                    }
                    tmp = time
                  }
                  if (continuous > 0) {
                    addTime.push(start-1)
                    addTime.push(start-2)
                    addTime.push(tmp+1)
                    addTime.push(tmp+2)
                  }
                  if (addTime) {
                    mainTimes.push(...addTime)
                    mainTimes = [...new Set(mainTimes)];
                    mainTimes.sort((a, b) => a - b);
                    reservationInfoMapArr.set(key, mainTimes)
                  }
                }
              }
            }
            this.reservationInfoMap = reservationInfoMapArr;
            if (reservationInfoMapArr.size) {
              // 버튼 비활성화
              const allTimes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21];
              // eslint-disable-next-line no-restricted-syntax
              for (const [key, value] of reservationInfoMapArr.entries()) {
                // 이게 0~21 모두 있으면 버튼 비활성화 처리ㅋ 
                // ? 무슨생각으로 이렇게 만듬?
                if ((this.isSat && value.length == 17) // 토, 일
                  || (!this.isSat && value.length == 24) // 월 - 금
                ) {
                  const rooms = this.roomInfo.filter(({ reservationMappingId }) => reservationMappingId.includes(key));
                  rooms.forEach((room) => {
                    room.disabled = true;
                    room.fullReservation = true;
                  });
                }
              }
            }
          }
        }
      });
    },
  },
};


</script>

<style>
@import '~@fullcalendar/core/main.min.css';
@import '~@fullcalendar/daygrid/main.min.css';
@import '~@fullcalendar/timegrid/main.min.css';

.fc-event-container .fc-title {font-size:.85em;}
.fc-event-container .fc-not-start .fc-content {margin-top: 0px!important;}
.fc-event-container .fc-not-end .fc-content {margin-bottom: 0px!important;}
.fc-event-container .fc-time-grid-event.r-impossible .fc-time,
.fc-event-container .fc-time-grid-event.r-impossible .fc-title {display:none;}
.fc-event-container .fc-time-grid-event.r-impossible {z-index: -1 !important;}
.fc-time-grid tr td .fc-time-grid-event:not(.r-select):not(.r-impossible) .fc-title {display:none;}
.fc-time-grid tr td:nth-child(2) .fc-time-grid-event:not(.r-select):not(.r-impossible) .fc-time {display:none;}
.fc-time-grid tr td:nth-child(2) .fc-time-grid-event:not(.r-select):not(.r-impossible) .fc-title {display:block;}
.fc-time-grid tr td:nth-child(2) .fc-event.r-waiting {background:rgba(151, 200, 236, 0.2) !important}
.fc-time-grid tr td:nth-child(2) .fc-time-grid-event.r-waiting:not(.r-select):not(.r-impossible) .fc-content {
  width:100%;margin:20px -19px;padding:10px 20px;opacity:1;background:rgba(151, 200, 236, 0.9) !important;}
.fc-time-grid tr td:nth-child(2) .fc-event.r-confirm {background:rgba(49, 144, 214, 0.2) !important}
.fc-time-grid tr td:nth-child(2) .fc-time-grid-event.r-confirm:not(.r-select):not(.r-impossible) .fc-content {
  width:100%;margin:20px -19px;padding:10px 20px;opacity:1;background:rgba(49, 144, 214, 0.9) !important;}
.v-hidden {visibility: hidden;width:0px!important}

.vdp-datepicker__calendar {
  background: #fff;
  width: 100%;
  border: 0px;
}
</style>
